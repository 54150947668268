import {
	apiURL_login,
	apiURL_register,
	apiURL_users,
	apiURL_user_me,
	apiURL_password_request,
	apiURL_user_password,
	apiURL_medics,
	apiURL_events,
	apiURL_shows,
	apiURL_performances,
	apiURL_performances_event,
	apiURL_sectors,
	apiURL_seats,
	apiURL_checkout,
	apiURL_tickets_me,
	apiURL_checkout_stripe,
	apiURL_config, apiURL_maps_performance, apiURL_coupons, apiURL_coupons_available,
}
	from "../models/api-urls";

import unregister from './apiService.config'; // No estamos usando el register(), pero importarlo hace que funcione el interceptor

import AuthService from './authService';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

class APIService {

	getConfig = async() => {
		const response = await fetch(apiURL_config, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	setParams = (urlStr, params) => {
		const url = new URL(urlStr);
		  // params = {lat:35.696233, long:139.570431};
		if(params) {
			Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
		}
		return url;
	};

	getCoupon = async(eventId, coupon) => {
		const response = await fetch(apiURL_coupons + '/' + eventId + '/' + coupon, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	checkCoupons = async(eventId) => {
		const response = await fetch(apiURL_coupons_available + '/' + eventId, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

    login = async(email, password) => {
        const response = await fetch(apiURL_login, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                email,
                password,
            })
        });
        const body = await response.json();

        if (response.status >= 300) throw Error(body.message);

        return body;
    };

    register = async(data) => {
        const response = await fetch(apiURL_register, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw Error(body.errors && body.errors.length ? body.errors[0] : body.message);

        return body;
    };

    updateUser = async(data) => {
        const response = await fetch(apiURL_user_me, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data)
        });
        const body = await response.json();

        if (response.status >= 300) throw Error(body.message);

        return body;
    };

    passwordRequest = async(email) => {
        const response = await fetch(apiURL_password_request, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                email,
            })
        });
        const body = await response.json();

        if (response.status >= 300) throw Error(body.message);

        return body;
    };

	updatePassword = async(user) => {
		const response = await fetch(apiURL_user_password, {
			method: 'PUT',
			headers,
			body: JSON.stringify(user)
		});
		const body = await response.json();

		if (response.status >= 300) throw Error(body.message);

		return body;
	};

    getUserMe = async() => {
        const response = await fetch(apiURL_user_me, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        /*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
        if (response.status >= 300) throw Error(body.message);

        return body;
    };

	getEvents = async(eventId) => {
		let url = apiURL_events;
		if(eventId) {
			url += '/' + eventId
		}
        const response = await fetch(url, {
            method: 'GET',
            headers
        });
        const body = await response.json();

        /*if (response.status >= 401) {
            this.callAuthServiceLogout();
            return false;
        }*/
        if (response.status >= 300) throw Error(body.message);

        return body;
    };

	getPerformance = async(performanceId) => {
		let url = apiURL_performances + '/' + performanceId;
		const response = await fetch(url, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	getPerformances = async(eventId) => {
		let url = apiURL_performances_event + '/' + eventId;
		const response = await fetch(url, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	getSectors = async(eventId, performanceId) => {
		let url = apiURL_sectors + '?event_id=' + eventId + '&performance_id=' + performanceId;
		const response = await fetch(url, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	getSeats = async(performanceId, sectorId) => {
		let url = apiURL_seats + '?performance_id=' + performanceId + '&seat_sector_id=' + sectorId;
		const response = await fetch(url, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	getPerformanceMapAsJSON = async(performanceId) => {
		let url = apiURL_maps_performance + '/' + performanceId + '/format/json';
		const response = await fetch(url, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	postCheckout = async(data) => {
		const response = await fetch(apiURL_checkout, {
			method: 'POST',
			headers,
			body: JSON.stringify(data)
		});
		const body = await response.json();

		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	postCheckoutStripe = async(data) => {
		const response = await fetch(apiURL_checkout_stripe, {
			method: 'POST',
			headers,
			body: JSON.stringify(data)
		});
		const body = await response.json();

		if (response.status >= 300) throw Error(body.message);

		return body;
	};

	getMyTickets = async() => {
		const response = await fetch(apiURL_tickets_me, {
			method: 'GET',
			headers
		});
		const body = await response.json();

		/*if (response.status >= 401) {
			this.callAuthServiceLogout();
			return false;
		}*/
		if (response.status >= 300) throw Error(body.message);

		return body;
	};

}

//unregister();

export default APIService;
