import React, {Component} from 'react';
import APIService from "../../modules/apiService";
import {SEAT_SELECTOR_TYPE, SEAT_SELECTOR_TYPE_MAP, SEAT_SELECTOR_TYPE_MULTISELECT} from "../../models/constants";
import Loading from "../../components/loading";
import CheckoutMapPage from '../checkoutMap';
import CheckoutMultiSelectPage from '../checkoutMultiSelect';
import locales_es from "../../locales/es";
import Helpers from "../../modules/helpers";

class CheckoutPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventId: Number(props.match.params.id),
            eventData: null,
            selectorType: null,
        };

        this.api = new APIService();
        this.helpers = new Helpers();
    }

    componentDidMount() {
        this.api.getEvents(this.state.eventId).then(res => {
            this.setState({
                selectorType: res.data[SEAT_SELECTOR_TYPE],
                eventData: res.data,
            })
        }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err))
        });
    }

    render() {
        return (
            <>
                {!this.state.selectorType ?
                <Loading/> : null}
                {this.state.selectorType === SEAT_SELECTOR_TYPE_MAP ?
                    <CheckoutMapPage {...this.props}
                                     eventData={this.state.eventData}
                                     showMainModal={(title, content) => this.props.showMainModal(title, content)}
                    />
                    : null}
                {this.state.selectorType === SEAT_SELECTOR_TYPE_MULTISELECT ?
                    <CheckoutMultiSelectPage {...this.props}
                                             eventData={this.state.eventData}
                                             showMainModal={(title, content) => this.props.showMainModal(title, content)}
                    />
                    : null}
            </>
        )
    }
}

export default CheckoutPage;
