import React, {Component} from 'react';
import Header from "../../components/header";
import {withFooter} from "../../components/footerHOC";
import Form from "./../../components/form";
import locales_es from "../../locales/es";
import {hrefDashboard} from "../../models/constants";
import AuthService from "../../modules/authService";
import Loading from "./../../components/loading";
import LoginPage from "./../../pages/login";
import AnalyticsService from "../../modules/analyticsService";


class RegisterPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			name: '',
			lastname: '',
			email: '',
			email_repeat: '',
			password: '',
			password_confirmation: '',
			loginMode: false,
		};

		this.auth = new AuthService();
		this.analyticsService = new AnalyticsService({
			eventId: this.props.eventId,
			pathname: this.props.pathname
		});
	}

	componentWillMount() {
		this.checkUserStatus();
	}

	async checkUserStatus() {
		const isLoggedIn = await this.auth.isLoggedUser();
		if (isLoggedIn && !this.props.modalMode) {
			this.successLoginRedirect();
		}
	}

	handleChange = state => ev => {
		this.setState({[state]: ev.target.value});
	};

	validateForm() {
		let errors = 0;

		if (!this.state.name
		  || !this.state.lastname
		  || !this.state.email
		  || !this.state.email_repeat
		  || !this.state.password
		  || !this.state.password_confirmation) {
			errors += 1;
			this.props.showMainModal('Error', 'Complete todos los campos del formulario');
		}

		if (this.state.email !== this.state.email_repeat) {
			errors += 1;
			this.props.showMainModal('Error', 'Los e-mails no coinciden');
		}

		if (this.state.password !== this.state.password_confirmation) {
			errors += 1;
			this.props.showMainModal('Error', 'Las contraseñas no coinciden');
		}


		return !errors;
	}

	send() {
		if (this.validateForm()) {
			this.setLoading(true);
			this.auth.register(this.state)
			  .then(() => {
				  // this.successLoginRedirect();
				  this.sendTrackingAndSuccess();
				  this.setLoading(false);
			  }).catch(err => {
				console.log(err);
				this.props.showMainModal(locales_es.errorModal.title, err.message);
				this.setLoading(false);
			});
		}
	}

	sendTrackingAndSuccess() {
		this.analyticsService.initGTM().then(() => {
			this.analyticsService.sendGTMEvent(
				'conversion',
				'registerCheckout',
				//() => this.successLoginRedirect() // TODO después habría que ver este callback.
				// TODO por ahora no es problema porque se trackea solo lo que vienen desde el modal de registro desde perfil del evento.
			);
			this.successLoginRedirect();
		}).catch(err => {
			console.error(err);
			this.successLoginRedirect();
		});
	}

	successLoginRedirect() {
		if (this.props.modalMode) {
			this.props.successMethod();
		} else {
			window.location.href = hrefDashboard;
		}
	}

	setLoading(bool) {
		this.setState({
			loading: bool
		});
	}

	render() {

		const inputs = [
			{
				label: 'Nombre',
				placeholder: 'Nombre',
				id: '1',
				state: 'name',
				value: this.state.name,
				type: 'text',
				required: true
			},
			{
				label: 'Apellido',
				placeholder: 'Apellido',
				id: '2',
				state: 'lastname',
				value: this.state.lastname,
				type: 'text',
				required: true
			},
			{
				label: 'Email',
				placeholder: 'Email',
				id: '3',
				state: 'email',
				value: this.state.email,
				type: 'email',
				required: true
			},
			{
				label: 'Repetir Email',
				placeholder: 'Repetir Email',
				id: '4',
				state: 'email_repeat',
				value: this.state.email_repeat,
				type: 'email',
				required: true
			},
			{
				label: 'Contraseña',
				placeholder: 'Contraseña',
				id: '5',
				state: 'password',
				value: this.state.password,
				type: 'password',
				required: true
			},
			{
				label: 'Repetir Contraseña',
				placeholder: 'Repetir Contraseña',
				id: '6',
				state: 'password_confirmation',
				value: this.state.password_confirmation,
				type: 'password',
				required: true
			}
		];

		return (
		  <div>

			  {this.state.loading ? <Loading/> : ''}

			  {this.props.modalMode ? null :
				<Header showMainModal={this.props.showMainModal}/>
			  }

			  <div>
				  {
					  this.state.loginMode ?
						<LoginPage modalMode={true}
						           showMainModal={this.props.showMainModal}
						           successMethod={this.props.successMethod}
						/>
						:
						<Form
						  inputs={inputs}
						  handleChange={this.handleChange}
						  onSubmit={() => this.send()}
						  onSubmitButtonText={locales_es.send}
						  showTerms={true}
						>
							<h2 className="form-signin-heading" data-i18n="registerForm.title">Registrate</h2>
						</Form>
				  }

				  {this.props.modalMode ?
					this.state.loginMode ?
					  <div className="container-fluid tcenter first-time-register-link">
						  <span data-i18n="registerForm.registerText1">¿No tienes cuenta?</span>
						  &nbsp;
						  <a onClick={() => {
							  this.setState({
								  loginMode: false
							  })
						  }} data-i18n="registerLink">Registrate</a>.&nbsp;
					  </div>
					  :
					  <div className="container-fluid tcenter first-time-register-link">
						  <span data-i18n="registerForm.registerText1">¿Ya tienes cuenta?</span>
						  &nbsp;
						  <a onClick={() => {
							  this.setState({
								  loginMode: true
							  })
						  }} data-i18n="registerLink">Inicia sesión</a>.&nbsp;
					  </div>
					:
					<div>
						{this.state.loginMode ? null
						  :
						  <div className="container-fluid tcenter first-time-register-link">
							  <span data-i18n="registerForm.registerText1">¿Ya tienes cuenta?</span>
							  &nbsp;
							  <a href="/login"
							     data-i18n="registerLink">Inicia sesión</a>.&nbsp;
						  </div>
						}
					</div>
				  }
			  </div>


		  </div>
		)
	}
}

export default RegisterPage;
