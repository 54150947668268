import React, {Component} from 'react';
import Header from "../../components/header";
import {withFooter} from "../../components/footerHOC";


class TermsPage extends Component {

    render() {
        return (
            <div>
                <Header showMainModal={this.props.showMainModal}/>

                <div className="container marketing">
                    <div className="container">
                        <strong>TÉRMINOS Y CONDICIONES</strong>
                        <p>Cuando adquiera sus Tickets a través del Sistema Fullshow-OnLine (www.fullshow.com.ar), por
                            favor asegúrese de conocer y aceptar las siguientes políticas, términos y condiciones:</p>
                        <p>Al utilizar los servicios de FullShow, el titular de datos personales presta su
                            consentimiento conforme a lo establecido en la ley 25.326 de Protección de Datos Personales,
                            a tenor de lo cual declara conocer y aceptar que sus datos personales integren la base de
                            datos de FullShow, otorgando por la presente, autorización expresa para: (i) el tratamiento
                            automatizado de dichos datos e información y (ii) su utilización para servicios actuales o
                            futuros, que desarrolle FullShow. El consumidor conoce y consiente que al aceptar el
                            casillero titulado “Acepto los términos y condiciones del servicio”, sus datos personales
                            serán cedidos por FullShow a el/los productor/es del evento quien/es podrá/n utilizarlos
                            para fines publicitarios. El consumidor podrá revocar el consentimiento otorgado en
                            cualquier momento comunicándose con la empresa a través de este mail:
                            soporte@fullshow.com.ar El titular de los datos personales tiene la facultad de ejercer el
                            derecho de acceso a los mismos en forma gratuita y a intervalos no inferiores a seis meses,
                            salvo que se acredite tener un interés legítimo al efecto conforme lo establecido en el
                            artículo 14, inciso 3 de la Ley Nº 25.326. El titular de los datos, tiene además la
                            posibilidad de ejercer los derechos de rectificación, y supresión de los datos conforme
                            artículo 6, inciso “e” de la Ley 25.326. Ley 25.326- Artículo 27- inciso 3. — (Archivos,
                            registros o bancos de datos con fines de publicidad). “El titular podrá en cualquier momento
                            solicitar el retiro o bloqueo de su nombre de los bancos de datos a los que se refiere el
                            presente artículo”. Decreto 1558/01 –Anexo I- Artículo.- 3º párrafo. “En toda comunicación
                            con fines de publicidad que se realice por correo, teléfono, correo electrónico, Internet u
                            otro medio a distancia a conocer, se deberá indicar, en forma expresa y destacada, la
                            posibilidad del titular del dato de solicitar el retiro o bloqueo, total o parcial, de su
                            nombre de la base de datos. A pedido del interesado, se deberá informar el nombre del
                            responsable o usuario del banco de datos que proveyó la información”. La DIRECCION NACIONAL
                            DE PROTECCION DE DATOS PERSONALES, Órgano de Control de la Ley Nº 25.326, tiene la
                            atribución de atender las denuncias y reclamos que se interpongan con relación al
                            incumplimiento de las normas sobre protección de datos personales. Los Tickets (en adelante
                            los “Ticket/s”) son vendidos por "LIGTH GREEN GROUP” con domicilio en Brown 574 oficina 1 y
                            2 Quilmes, Nro. de CUIT 30-71586049-6, (en adelante “FullShow”) como agente, actuando por
                            cuenta y orden del Promotor/Productor/Teatro/Estadio (en adelante el “Organizador”) del
                            evento o espectáculo. El Organizador es el único y directo responsable del espectáculo o
                            evento a realizarse, sujeto a las condiciones de venta del Organizador.</p>
                        <p>
                            <ul>
                                <li>
                                    Si la fecha de un evento variara por alguna circunstancia, los Tickets serán válidos
                                    para la fecha definitiva que fije e informe el Organizador. La reprogramación del
                                    evento en ningún caso dará derecho al consumidor a reclamar la devolución del valor
                                    del ticket.
                                </li>
                            </ul>
                        </p>

                        <p>
                            Si un evento es suspendido o cancelado el consumidor tendrá derecho a solicitar la
                            devolución del valor del ticket. Todas las devoluciones serán responsabilidad del
                            Organizador, pudiendo FullShow colaborar en la gestión de dichas devoluciones al consumidor.
                        </p>
                        <p>
                            <ul>
                                <li>El consumidor tiene derecho a revocar la aceptación durante el plazo de 10 (diez)
                                    días corridos contados a partir de la fecha en que se celebre el contrato, sin
                                    responsabilidad alguna, salvo que ya hubiese utilizado el ticket. Esta facultad no
                                    puede ser dispensada ni renunciada. Dado que FullShow actúa como intermediario y por
                                    cuenta y orden del Organizador, cualquier reclamo deberá ser ejercido exclusivamente
                                    ante el Organizador. Sin perjuicio de ello, FullShow, sin asegurar resultado o
                                    garantía alguna, podrá ofrecer a su exclusivo criterio sus servicios en base a
                                    esfuerzos razonables para gestionar dichos reclamos.
                                </li>
                                <li>El Organizador se reserva el derecho de agregar, modificar o sustituir artistas,
                                    variando los programas, precios y ubicaciones difundidas, así como la capacidad del
                                    auditorio, conforme esto sea informado oportunamente. El Organizador se reserva el
                                    derecho de admisión y permanencia. Las llegadas tarde del público implicarán que el
                                    ingreso del mismo se efectúe en el intervalo o cuando el Organizador lo considere
                                    oportuno a su exclusivo criterio.
                                </li>
                                <li>Antes de ingresar al evento, los espectadores podrán ser sometidos a un chequeo por
                                    parte del personal de seguridad, impidiéndose la entrada a quien se niegue a
                                    recibirlo, no acarreando esto ninguna responsabilidad o consecuencia para el
                                    Organizador.
                                </li>
                                <li>No será permitido el ingreso al evento con pirotecnia, grabadoras, filmadoras,
                                    cámaras de fotografía y de video, ni cualquier elemento similar a los mencionados a
                                    criterio del Organizador, pudiendo los mismos ser retirados del lugar y destruido su
                                    contenido. El Organizador podrá solicitar que se apague todo equipo de radio llamada
                                    o teléfono celular antes del acceso al evento.
                                </li>
                                <li>El sistema FullShow-Online permite a los clientes adquirir tickets a través de
                                    Internet u otros medios digitales similares. El sistema selecciona el mejor lugar
                                    disponible al momento de realizar su compra, de acuerdo al nivel de precio
                                    solicitado.
                                </li>
                                <li>Los Tickets también podrán ser adquiridos por el consumidor en las boleterías que el
                                    Organizador habilite para cada evento.
                                </li>
                                <li>Cada ticket comprado a través del Centro de Atención Telefónica, Puntos de Venta
                                    FullShow y/o de FullShow-Online, se encuentra sujeta a un cargo por servicio
                                    adicional del precio del ticket(los cuales se informan en la lista de precios para
                                    cada evento).
                                </li>
                                <li>Toda compra realizada a través del sistema FullShow permite al usuario optar entre
                                    distintas alternativas de entrega de los Tickets (en locales, en la boletería del
                                    evento o espectáculo, envío a domicilio); algunas de las cuales están sujetas a un
                                    cargo adicional que será informado por FullShow a través de los distintos medios de
                                    contacto con el adquirente.
                                </li>
                                <li>Los cargos por servicio (incluyendo cargos adicionales por envío a domicilio) que
                                    hubieren sido efectivamente prestados por FullShow no serán en ningún caso devueltos
                                    al consumidor, ni aún en caso de suspensión, cancelación o reprogramación del
                                    evento.
                                </li>
                                <li>El adquirente de Tickets es responsable de chequear, aceptar y confirmar los
                                    términos y condiciones de su compra previo a realizarla, incluyendo sin limitación:
                                    (i) la descripción y especificación del evento para el cual adquirió los Tickets,
                                    (ii) los plazos y condiciones de la entrega, (iii) los precios y condiciones de
                                    pago, (iv) los costos y cargos adicionales. Por ello antes de completar su compra,
                                    por favor revise cuidadosamente el evento, sección y ubicación deseada, ya que una
                                    vez confirmada su compra no se permiten cambios, reembolsos y/o devoluciones por
                                    este motivo.
                                </li>
                                <li>La confirmación de la compra que usted realiza con su tarjeta de crédito o débito
                                    está sujeta a la autorización de la empresa emisora de ésta. Las entregas de los
                                    Tickets se realizan únicamente dentro del territorio de la República Argentina.
                                </li>
                                <li>Si usted selecciona el servicio "Envío a Domicilio", su compra deberá efectuarla con
                                    un mínimo de 15 días hábiles previos a la realización del evento o espectáculo en el
                                    caso de utilizar el servicio "Estándar" (para Capital Federal y GBA); 20 días
                                    hábiles en el caso de utilizar el servicio "Interior", dependiendo de la
                                    disponibilidad de cada servicio para el evento o espectáculo en cuestión.
                                </li>
                                <li>Servicios de envío especiales: en determinados casos especiales en los cuales el
                                    Organizador y/o FullShow podría modificar los plazos mencionados, dichos plazos como
                                    cualquier otra condición de venta, serán informados al cliente, conforme lo indique
                                    el Organizador y/o FullShow.
                                </li>
                                <li>Asegúrese de marcar la opción de envío correcta al momento de realizar su compra.
                                </li>
                                <li>Dentro de los plazos indicados en cada compra, el correo realizará la siguiente
                                    cantidad de visitas obligatorias:
                                </li>
                                <li>Servicio Estándar: 2 (dos) visitas obligatorias. - Servicio Interior: 2 (dos) visitas
									obligatorias.</li>
                                <li>Todas las visitas se realizan en días hábiles, contando a partir de las 48 horas
                                    siguientes al día hábil en que fuera realizada la compra; excepto si la compra se
                                    realiza durante los días Viernes, Sábado, Domingo o en víspera de feriado; en este
                                    último caso los plazos corren a partir de las 72 horas siguientes al de la
                                    compra.</li>
                                <li>Si decide utilizar el servicio de “Entrega en la Boletería”, significa que usted
                                    recogerá sus Tickets en la boletería especial del evento, cuya ubicación será
                                    informada por FullShow. Mucho público elige la presente opción, situación que genera
                                    largas filas en horarios muy cercanos al comienzo del espectáculo. FullShow no será
                                    responsable por la demora que usted pudiera sufrir en acceder a su ticket y al
                                    espectáculo. Motivo por el que recomendamos llegar con tiempo de antelación si usted
                                    optara por esta modalidad de entrega del ticket.
                                </li>
                                <li>Cuando la compra es realizada con tarjeta de crédito o débito, tanto cuando adquiera
                                    sus Tickets por Boletería como cuando se envíen a domicilio, las mismas SOLAMENTE
                                    pueden ser entregadas a la persona que realizó la compra, sea ésta Titular de la
                                    Tarjeta de crédito o débito utilizada para realizar la compra, o de la tarjeta
                                    adicional utilizada a tales fines mediante acreditación fehaciente de su identidad.
                                    Por ello, no olvide tener consigo lo siguiente al momento del retiro por Boletería o
                                    de entrega a domicilio:
                                </li>
                                <li>Tarjeta de crédito o débito del titular o adicional de la misma con la cual se
                                    realizó la compra. El titular de la tarjeta, o su adicional, que se exhiba debe
                                    coincidir con quien realizó la compra.
                                </li>
                                <li>Documento de identidad (Cédula de Identidad, DNI, Libreta de Enrolamiento, Libreta
                                    Cívica, Pasaporte) del Titular de la Tarjeta de Crédito/Débito o adicional de la
                                    misma, según corresponda.
                                </li>
                                Tenga en cuenta estos requisitos, ya que son necesarios para hacerle entrega de sus
                                Tickets y FullShow deslinda todo tipo de responsabilidad en este sentido. Recuerde que
                                estos procedimientos han sido implementados para su seguridad como usuario de Tarjeta de
                                crédito o débito. FullShow no entregará Tickets adquiridos por titulares de tarjeta de
                                crédito o débito a quienes exhiban un adicional de la misma, siempre que este adicional
                                no haya sido utilizado para la compra.
                                <li>Cuando retire o reciba sus Tickets, guárdelos en un lugar seguro, lejos del calor,
                                    humedad o de la luz solar. FullShow no realiza reposición de Tickets si estos han
                                    sido perdidos, robados o dañados, quedando estos bajo la exclusiva responsabilidad
                                    del cliente luego de retirados o recibidos.</li>
                                <li>Para tener acceso a FullShow-Online usted requiere tener habilitado el modo gráfico
                                    y aceptar cookies en su browser o navegador. Si su navegador o browser tiene
                                    deshabilitadas estas dos opciones, por favor habilítelas y vuelva a cargar esta
                                    página.
                                </li>
                                <li>Los Tickets sólo serán considerados válidos para ingresar al evento siempre que sean
                                    adquiridos a través del sistema FullShow. Para evitar situaciones tales como reventa
                                    ilegal, falsificaciones, uso de Tickets robado, Tickets con doble acceso entre
                                    otras, le recomendamos comprar sus Tickets únicamente a través del sistema FullShow
                                    en forma directa.
                                </li>
                                <li>Los Tickets no podrán ser utilizados en ningún caso para su reventa y/o aplicación
                                    comercial o de promoción alguna sin la previa autorización por escrito del
                                    Organizador o de FullShow.
                                </li>
                                <li>Eventos transmitidos en modo streaming (en adelante los “Eventos On-Line”).</li>
                                <li>Además de la aplicabilidad de todos los términos y condiciones detallados
                                    previamente, en cuanto resultaren aplicables, para los Eventos On-Line serán también
                                    de aplicación los siguientes términos y condiciones:
                                </li>
                                <li>Los accesos para los Eventos On-Line (en adelante los “Accesos”) son vendidos por
                                    FullShow como agente, actuando por cuenta y orden del Organizador. El Organizador es
                                    el único y directo responsable del espectáculo o evento a realizarse, sujeto a las
                                    condiciones de venta del Organizador.
                                </li>
                                <li>Los Eventos On-Line serán transmitidos exclusivamente vía streaming, es decir a
                                    través de la red de internet en forma on-line.
                                </li>
                                <li>Los Eventos On-Line podrán ser o bien transmitidos en vivo o bien reproducciones de
                                    eventos grabados y editados con anterioridad.
                                </li>

                                <li>Para acceder al Evento On-Line será preciso ingresar a la plataforma que disponga el
                                    Organizador del Evento On-Line, según fuere indicado en las condiciones de compra
                                    del Evento On-Line. Toda plataforma digital de reproducción se encontrará bajo la
                                    única y directa responsabilidad del Organizador, toda vez que la intervención de
                                    FullShow se limita únicamente a la comercialización de los Accesos para los Eventos
                                    On-Line.
                                </li>
                                <li>Para visualizar y acceder a un Evento On-Line el consumidor deberá (i) contar con
                                    acceso a una red de internet con velocidad suficiente para visualizar contenido
                                    streaming y (ii) contar con un dispositivo electrónico que permita el acceso a
                                    internet (ej. Celular, Tablet, Smart TV, computadora) y que cuente con pantalla y
                                    audio.
                                </li>
                                <li>El acceso a una red de internet con velocidad suficiente para visualizar contenido
                                    streamings responsabilidad exclusiva del consumidor. El consumidor entiende que de
                                    no contar con acceso a una red de internet con velocidad suficiente o en caso que su
                                    red de internet sufra interrupciones o micro cortes, ello afectará su posibilidad de
                                    visualizar el Evento On-Line y/o la calidad de visualización del Evento On-Line.
                                </li>
                                <li>El adquirente de Accesos para Eventos On-Line es responsable de chequear, aceptar y
                                    confirmar los términos y condiciones de su compra previo a realizarla, incluyendo
                                    sin limitación: (i) la descripción y especificación del Evento On-Line para el cual
                                    adquirió los Accesos, (ii) las condiciones técnicas requeridas para la visualización
                                    del Evento On-Line y (iii) los precios y condiciones de pago. Por ello antes de
                                    completar su compra, por favor revise cuidadosamente estas condiciones ya que una
                                    vez confirmada su compra no se permiten cambios, reembolsos y/o devoluciones por
                                    este motivo.
                                </li>
                                <li> [Recomendamos al consumidor que utilice el navegador Google Crome para una mejor
                                    visualización del Evento On-Line].
                                </li>
                                <li>El Evento On-Line estará disponible para su visualización por el plazo adicional que
                                    se indicare en las condiciones particulares de cada Evento On-Line.
                                </li>
                                <li>A concretar la compra del Acceso para un Evento On-Line, el consumidor recibirá un
                                    mail a la casilla de correo electrónico informada al momento de la compra, en el que
                                    recibirá un link de acceso al Evento On-Line y una contraseña. El consumidor
                                    entiende y reconoce que la contraseña recibida es única y sólo permitirá 1 (un)
                                    acceso al Evento On-Line.
                                </li>
                                <li>Se encuentra absolutamente prohibida la grabación y/o transmisión y/o retransmisión
                                    del Evento On-Line por cualquier medio incluyendo sin limitación grabadoras,
                                    filmadoras, cámaras de fotografía y cámaras de video.
                                </li>
                            </ul>
                        </p>
                        <p>Ante cualquier consulta, no dude en comunicarse con nosotros, a través del mail:
                            soporte@fullshow.com.ar</p>


                    </div>
                </div>


            </div>
        )
    }
}

export default withFooter(TermsPage);
