import React, {Component} from 'react';
import Header from "../../components/header";
import Loading from "./../../components/loading";
import {withFooter} from "../../components/footerHOC";
import APIService from "../../modules/apiService";
import DateTimeService from "../../modules/DateTimeService";
import AuthService from "../../modules/authService";

class ProfilePage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tickets: null,
			loading: false,
			userData: null,
		};

		this.api = new APIService();
		this.dateTimeService = new DateTimeService();
		this.auth = new AuthService();
	}

	componentDidMount() {
		if (!this.auth.isLoggedUser()) {
			window.location.href = '/';
			return;
		}
		this.auth.getRemoteUserData().then(res => {
			this.setState({
				userData: res.data.user
			});
		}).catch(err => {
			this.props.showMainModal('Error', err.message);
		})
	}

	render() {

		const {userData} = this.state;
		return (
		  <div>
			  {this.state.loading ? <Loading/> : ''}

			  <Header showMainModal={this.props.showMainModal}/>

			  <div className="container">
				  <div className="row">
					  <div className="container-fluid">
						  <h2 className="sub-header">Datos Personales</h2>
					  </div>
				  </div>

				  {userData === null ? <Loading/> :
					userData ?
					  <div>
						  <div className="row">
							  <div className="container-fluid">
								  <div className="col-md-6">
									  <h4>Nombre</h4>
									  <input
										value={userData.name}
										className="form-control" required="required"/>
									  <h4>Apellido</h4>
									  <input
										value={userData.lastname}
										className="form-control"
										required="required"/>
									  {/*<h4>DNI</h4>
									  <input type="number"
									         value={userData.identification}
									         className="form-control"
									         required="required"/>
									  <h4>Teléfono</h4>
									  <input type="number"
									         value={userData.phone}
									         className="form-control"
									         required="required"/>*/}
								  </div>

								  {/*<div className="col-md-6">
									  <h4>Domicilio</h4>
									  <input value={userData.address} className="form-control"/>
									  <h4>Ciudad</h4>
									  <input value={userData.city} className="form-control"/>
								  </div>*/}
							  </div>
						  </div>

						  {/*<div className="row form-control__buttons">
							  <div className="col-md-12">
								  <button className="btn btn-info fright" role="button">Guardar</button>
							  </div>
						  </div>
*/}
						  <div className="row" ng-hide="viewType.sellerEdit">
							  <div className="container-fluid"><h2 className="sub-header">Cuenta</h2>
								  <div className="col-md-6">
									  <h4>E-mail Registrado</h4>
									  <input className="form-control"
									         value={userData.email}
									         disabled="disabled"/></div>
								  {/*<div className="col-md-6"><h4>Cambiar Password</h4>
									  <input
										className="form-control"
										ng-model="user.password"
										type="password"/><br/>
									  <input
										className="form-control"
										type="password"/><br/>
									  <a className="btn btn-warning fright"
									     role="button">Cambiar
										  Contraseña</a>
								  </div>*/}
							  </div>
						  </div>
					  </div>
					  :
					  <h1>Ha ocurrido un error</h1>}
			  </div>
		  </div>
		)
	}
}

export default withFooter(ProfilePage);