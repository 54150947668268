import React, {Component} from 'react';
import Input from './../formInput';
import FormSelect from "../formSelect";

export class Form extends Component {
	render() {
		const {handleChange, onSubmit, onSubmitButtonText, inputs, showTerms} = this.props;
		return (
		  <form onSubmit={(ev) => {
			  ev.preventDefault();
			  onSubmit();
		  }} className="form-signin" role="form">
			  {this.props.children}
			  <div className="form-group">
				  {inputs.map((input) => {
						return (
						  input.type === 'select' ?
							<FormSelect
							  value={input.value === null ? '' : input.value}
							  label={input.label}
							  type={input.type}
							  key={input.id}
							  id={input.id}
							  required={input.required}
							  disabled={input.disabled}
							  options={input.options}
							  customClassName={input.customClassName}
							  handleChange={handleChange(input.state)}
							/>
						    :
						    <Input
							  value={input.value === null ? '' : input.value}
							  label={input.label}
							  placeholder={input.placeholder}
							  type={input.type}
							  key={input.id}
							  id={input.id}
							  required={input.required}
							  disabled={input.disabled}
							  customClassName={input.customClassName}
							  handleChange={handleChange(input.state)}
						    />
						)
					}
				  )
				  }
			  </div>
			  {showTerms ?
			    <div className="form-group">
				    <div className="align-center">
					    <label htmlFor="inputCheckTerms" className="checkbox-terms">
						    <input id="inputCheckTerms" className="form-control"
						           required={true} type="checkbox" defaultChecked />
							    <a target="_blank"
							       href="/terms"
							       data-i18n="registerForm.checkTermsLabel">Acepto los términos y condiciones</a>
					    </label>
				    </div>
			    </div>
			  : null}
			  <div className="form-group">
				  <div className="align-center">
					  <button type="submit"
					          className="btn btn-lg btn-primary btn-block">
						  {onSubmitButtonText}
					  </button>
				  </div>
			  </div>
		  </form>
		)
	}
}

export default Form
