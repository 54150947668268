import React, {Component} from 'react';
import {REACT_APP_STRIPE_LIBRARY, stripeScriptId} from "../../models/constants";
import Loading from "../../components/loading";
import {Elements, StripeProvider} from "react-stripe-elements";
import StripeForm from '../../components/checkout/stripeForm';
import AcceptedCards from './../../images/stripe_credit-card-logos.png';

class StripeCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            number: '',
            stripe: null,
            sendingFlag: false,
        };
    }

    setStripeKey() {
        if (!window.stripeKey) {
            throw new Error('Stripe key not found');
        }
        const options = {
            locale: 'es'
        };
        this.setState({stripe: window.Stripe(window.stripeKey, options)});
    }

    componentDidMount() {
        let script = document.getElementById(stripeScriptId);
        if (!script) {
            script = document.createElement('script');
            script.id = stripeScriptId;
            // script.src = process.env.REACT_APP_STRIPE_LIBRARY;
            script.src = REACT_APP_STRIPE_LIBRARY;
            document.body.appendChild(script);
            script.addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                this.setStripeKey()
            });
            return;
        }
        // Default
        this.setStripeKey()
    }

    render() {
        return (
            <section>
                {!this.state.stripe ? <Loading/> :
                    <div className="eventticket-card-form">
                        <StripeProvider stripe={this.state.stripe}>
                            <Elements>
                                <StripeForm
                                    onSuccess={this.props.onSuccess}
                                    onError={this.props.onError}
                                    data={this.props.data}
                                    totalPrice={this.props.totalPrice}/>
                            </Elements>
                        </StripeProvider>
                        <b>Accepted Cards</b><br />
                        <img src={AcceptedCards} alt="Visa, MasterCard, American Express, Discover, JBC y Diners"/>
                    </div>
                }
            </section>
        )
    }
}

export default StripeCheckout;
