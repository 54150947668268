import React, {Component} from 'react';
import Header from "../../components/header";
import {withFooter} from "../../components/footerHOC";


class PrivacyPage extends Component {

	render() {
		return (
			<div>
				<Header showMainModal={this.props.showMainModal}/>

				<div className="container marketing">
					<div className="container tcenter"><h3>Política de Privacidad</h3> <strong>¿Cómo protegemos tu
						información?</strong> <p>Implementamos una variedad de medidas de seguridad para mantener
						la seguridad de su información personal cuando ingresa, envía o accede a su información
						personal.<br/><br/> <strong>¿Divulgamos información a terceros?</strong></p><p>No vendemos,
						comercializamos ni transferimos a terceros su información de identificación personal. Esto
						no incluye a terceros de confianza que nos ayudan a operar nuestras páginas web, llevar a
						cabo nuestro negocio o brindarle servicio, siempre y cuando esas partes acuerden mantener
						esta información confidencial. También podemos divulgar su información cuando consideremos
						que la divulgación es apropiada para cumplir con la ley, hacer cumplir nuestras políticas
						o proteger nuestros derechos u otros, propiedad o seguridad. Sin embargo, la información
						de identificación no personal se puede proporcionar a otras partes para mercadeo,
						publicidad u otros usos. Tu consentimiento </p><p>Al usar nuestra páginas web, usted
						acepta la política de privacidad de nuestra página web</p></div>
				</div>


			</div>
		)
	}
}

export default withFooter(PrivacyPage);
