import React, {Component} from 'react';


export default class FormSelect extends Component {

	render(){
		const {id, name, value, required, options, handleChange, customClassName} = this.props;
		const defaultClassName = 'mdl-textfield__input ';

		return (
		  <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
			  <select className={defaultClassName + customClassName}
			          onChange={handleChange}
			          name={name}
			          value={value}
			          key={id}
			          required={required}>
				  {options.map(opt => {
				  	return (
				  		<option key={'opt_' + opt.id} id={opt.id} value={opt.value} name={opt.name}>{opt.name}</option>
				    )
				  })}
			  </select>
			  <label className="mdl-textfield__label" htmlFor="inputName">{this.props.label}</label>
		  </div>
		);
	}
}