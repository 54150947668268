import React, {Component} from 'react';
import "./index.css";

export default class loading extends Component {

	render() {
		return (
		  <div className="woopi--loading">
			  <div className="woopi--loading-container">
				  <div className="dots">
					  <div className="dot" />
					  <div className="dot" />
					  <div className="dot" />
					  <div className="dot" />
					  <div className="dot" />
					  <div className="dot" />
					  <div className="dot" />
					  <div className="dot" />
					  <div className="dot" />
					  <div className="dot" />
					  <div className="dot" />
				  </div>

				  <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
					  <defs>
						  <filter id="goo">
							  <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="9"/>
							  <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -7"
							                 result="goo"/>
							  <feBlend in2="goo" in="SourceGraphic" result="mix"/>
						  </filter>
					  </defs>
				  </svg>
			  </div>
		  </div>
		)
	}
}
