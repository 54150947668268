import {analyticsConfig} from "../models/analytics.local";
import ReactPixel from 'react-facebook-pixel';

export default class AnalyticsService {
    constructor(props) {
        this.props = props;
        // this.setAnalytics();
    }

    hasTracking(name) {
    	let trackingObj = null;
    	analyticsConfig.map(config => {
    		if (config.event_id === this.props.eventId) {
    			trackingObj = config;
			}
		});
    	return trackingObj;
	}

    initFacebook() {
    	const trackingObj = this.hasTracking();
		if (trackingObj) {
			process.env.REACT_APP_DEBUG === 'true' && console.log('tracking event on FB...');
			if (trackingObj.facebook) {
				process.env.REACT_APP_DEBUG === 'true' && console.log('FB init...');
				const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
				const options = {
					autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
					debug: process.env.REACT_APP_DEBUG === 'true', // enable logs
				};
				ReactPixel.init(trackingObj.facebook.pixel, advancedMatching, options);

				if (trackingObj.facebook.pageViews.includes(this.props.pathname)) {
					process.env.REACT_APP_DEBUG === 'true' && console.log('fb tracking page view: ' + this.props.pathname);
					ReactPixel.pageView();
				}
			} else {
				process.env.REACT_APP_DEBUG === 'true' && console.log('No FB initialization');
			}
			return true
		} else {
			process.env.REACT_APP_DEBUG === 'true' && console.log('no FB tracking available');
		}
    }

    initGTM() {
    	return new Promise((resolve, reject) => {
			const trackingObj = this.hasTracking();
			if (trackingObj) {
				process.env.REACT_APP_DEBUG === 'true' && console.log('tracking event on GTM...');
				if (trackingObj.google) {
					if (document.getElementById(trackingObj.google.id)) {
						return resolve();
					}
					const script = document.createElement('script');
					script.id = trackingObj.google.id;
					script.async = '';
					script.src = 'https://www.googletagmanager.com/gtag/js?id=' + trackingObj.google.id;

					script.onload = () => {
						console.log('gtm loaded');
						this.googleAnalyticsExecution(trackingObj.google.id);
						resolve();
					};

					script.onerror = (err) => {
						reject(err)
					};

					document.body.appendChild(script);
				} else {
					process.env.REACT_APP_DEBUG === 'true' && console.log('No GTM initialization');
					resolve();
				}
			} else {
				process.env.REACT_APP_DEBUG === 'true' && console.log('no GTM tracking available');
				resolve();
			}
		})
    }

    gtag() {
        window.dataLayer.push(arguments);
    }

    googleAnalyticsExecution(gaId) {
        window.dataLayer = window.dataLayer || [];
        this.gtag('js', new Date());
        this.gtag('config', gaId);
    }

	sendGTMEvent(googleEventType, googleEventKeyname, cb) {
		const trackingObj = this.hasTracking();
		if (trackingObj) {
			if(!window.gtag){
				console.log(window.gtag);
				return new Error('se solicitó enviar un evento sin cargar GTM correctamente');
			}
			if (trackingObj.google && trackingObj.google.events) {
				process.env.REACT_APP_DEBUG === 'true' && console.log('Tracking Google Event...');
				const keys = Object.keys(trackingObj.google.events);
				if (keys.includes(googleEventKeyname)) {
					const eventKey = trackingObj.google.events[googleEventKeyname];
					process.env.REACT_APP_DEBUG === 'true' && console.log('GoogleEventType: ' + googleEventKeyname);
					process.env.REACT_APP_DEBUG === 'true' && console.log('GoogleEventId: ' + eventKey);
					window.gtag('event', 'conversion', {
						'send_to': eventKey,
						'event_callback': cb,
					});
				}
			}
		}
	}
}
