import React, {Component} from 'react';
import Header from "../../components/header";
import locales_es from "../../locales/es";
import {hrefDashboard, hrefHome} from "../../models/constants";
import AuthService from "../../modules/authService";
import Loading from "./../../components/loading";


class CongratsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            status: props.match.params.status,
        };

        this.auth = new AuthService();
    }

    componentWillMount() {
        this.checkUserStatus();
    }

    async checkUserStatus() {
        const isLoggedIn = await this.auth.isLoggedUser();
        if (!isLoggedIn) {
            window.location.href = hrefHome;
        }
    }

    handleChange = state => ev => {
        this.setState({[state]: ev.target.value});
    };

    setLoading(bool) {
        this.setState({
            loading: bool
        });
    }

    render() {

        const {status} = this.state;

        return (
            <div>

                {this.state.loading ? <Loading/> : ''}

                {this.props.modalMode ? null :
                    <Header showMainModal={this.props.showMainModal}/>
                }

                <div>
                    {status === 'success' ?
                        <div className="col text-center">
                            <h1>Su compra ha sido exitosa.</h1>
                            <div className="col text-center">
                                <br/>
                                <a href={hrefDashboard} type="button"
                                   className="btn btn-success">Ver Entradas</a>
                                <br/>
                                <br/>
                            </div>
                            <h3>Los tickets también fueron enviados a su e-mail.</h3>
                            <h4>Si no ha recibido el correo correspondiente, recuerde también revisar en la bandeja de
                                correo no deseado (SPAM).</h4>
                            <h5><u>Puede consultar sus tickets también desde nuestro sitio web iniciando sesión con su
                                usuario y su contraseña.</u></h5>
                        </div>
                        : null
                    }

                    {status === 'error' ?
                        <div className="container pt-5">
                            <div className="row mt-5">
                                <div className="col text-center">
                                    <h1>Ha ocurrido un error al procesar su pago</h1>
                                    <h2>Intente nuevamente más tarde</h2>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col text-center">
                                    <a href={hrefHome} target="_blank" type="button"
                                       className="btn btn-danger">Volver a intentar</a>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                        : null
                    }

                </div>


            </div>
        )
    }
}

export default CongratsPage;
