import React, {Component} from 'react';
import Header from "../../components/header";
import {withFooter} from "../../components/footerHOC";
import Form from "./../../components/form";
import locales_es from "../../locales/es";
import {hrefLogin} from "../../models/constants";
import AuthService from "../../modules/authService";
import Loading from "./../../components/loading";


class RecoveryPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			email: '',
			password: '',
		};

		this.auth = new AuthService();
	}

	handleChange = state => ev => {
		this.setState({[state]: ev.target.value});
	};

	validateForm() {
		return !this.state.email
		  ? this.props.showMainModal('Error', 'Complete su dirección de correo registrada')
		  : true;
	}

	send() {
		if (this.validateForm()) {
			this.setLoading(true);
			this.auth.recovery(this.state.email)
			  .then((res) => {
				  this.props.showMainModal(locales_es.successModal.title, res.message);
				  this.successLoginRedirect();
				  this.setLoading(false);
			  }).catch(err => {
				console.log(err);
				this.props.showMainModal(locales_es.errorModal.title, err.message);
				this.setLoading(false);
			});
		}
	}

	successLoginRedirect() {
		if (this.props.modalMode) {
			this.props.successMethod();
		}
	}

	setLoading(bool) {
		this.setState({
			loading: bool
		});
	}

	render() {

		const inputs = [
			{
				label: 'Email',
				placeholder: 'Email',
				id: '1',
				state: 'email',
				value: this.state.email,
				type: 'email',
				required: true
			}
		];

		return (
		  <div>

			  {this.state.loading ? <Loading/> : ''}

			  {this.props.modalMode ? null :
				<Header showMainModal={this.props.showMainModal}/>
			  }

			  <div>

				  <Form
					inputs={inputs}
					handleChange={this.handleChange}
					onSubmit={() => this.send()}
					onSubmitButtonText={locales_es.send}
				  >
					  <h2 className="form-signin-heading" data-i18n="loginForm.title">No recuerdo mi contraseña</h2>
					  <p data-i18n="loginForm.p">Ingresa la dirección de correo para restaurar tu contraseña</p>
				  </Form>

				  {this.props.modalMode ?
					null
					:
					<>
						<div className="container-fluid tcenter first-time-register-link">
							<span data-i18n="loginForm.registerText1">¿Ya tienes cuenta?</span>
							&nbsp;
							<a href="/login"
							   data-i18n="registerLink">Iniciar sesión</a>.
						</div>
					</>
				  }

			  </div>


		  </div>
		)
	}
}

export default RecoveryPage;