import React, {Component} from 'react';
import "./index.css";
import spinner from "./../../images/loading.gif";


export default class Spinner extends Component {

	render() {
		return (
		  <div className="spinner-container">
			  <img src={spinner} />
		  </div>
		)
	}
}
