import React, {Component} from 'react';
import Header from "../../components/header";
import Loading from "./../../components/loading";
import Spinner from "./../../components/spinner";
import {withFooter} from "../../components/footerHOC";
import APIService from './../../modules/apiService';
import DateTimeService from "../../modules/DateTimeService";
import MultiSelect from "react-multi-select-component";

// import referenceMap from "./../../images/PLANO_GENERAL.png";
// import referenceMapDiciembre from "./../../images/PLANO-GENERAL-diciembre.gif";
import AuthService from "../../modules/authService";
import RegisterPage from "../../pages/register";
import Review from "../../components/review";
import InnerImageZoom from 'react-inner-image-zoom';
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import StripeCheckout from "../../components/checkout/stripeCheckout";
import {hrefCongratsSuccess, MERCADO_PAGO_BASIC, SECTOR_TYPE_QTY, STRIPE} from "../../models/constants";

class CheckoutMultiSelectPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checkout: null,
            event: this.props.eventData,
            performances: null,
            performanceId: '',
            eventId: Number(props.match.params.id),
            sectors: null,
            sectorId: '',
            sectorType: null,
            seats: null,
            seatsIds: '',
            quantity: 0,
            selectedSectorImage: '',
            showReview: false,
        };

        this.api = new APIService();
        this.dateTimeService = new DateTimeService();
        this.auth = new AuthService();
        this.helpers = new Helpers();

        this.confirm = this.confirm.bind(this);
    }

    componentDidMount() {
        this.getPerformances();
    }

    getPerformances() {
        this.api.getPerformances(this.state.eventId)
            .then((res) => {
                const data = res.data.filter(perf => this.dateTimeService.getTimeRemaining(perf.date).total > 0);
                this.setState({
                    performances: data,
                }, () => {
                    const fakeEvent = {
                        target: {
                            value: data[0].id
                        }
                    };
                    this.onChangePerformance(fakeEvent);
                });
            }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, err.message)
        });
    }

    onChangePerformance(e) {
        this.setState({
            performanceId: e.target.value,
            sectorId: '',
            seatsIds: '',
        }, () => {
            this.getSectors();
        });
    }

    setSectorsLoading(bool) {
        this.setState({
            sectorLoading: bool
        });
    }

    getSectors() {
        this.setSectorsLoading(true);
        this.setState({
            sectors: null,
        });
        this.api.getSectors(this.state.eventId, this.state.performanceId)
            .then((res) => {
                this.setState({
                    sectors: res.data,
                }, () => {
                    const fakeEvent = {
                        target: {
                            value: res.data[0].id
                        }
                    };
                    this.onChangeSector(fakeEvent);
                    this.setSectorsAvailability();
                });
            }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, err.message)
        });
    }

    onChangeSector(e) {
        this.setState({
            sectorId: e.target.value,
        }, () => {
            this.getSeats();
            this.setSectorPrice();
            this.setSectorImage();
            this.setSectorType();
        });
    }

    setSectorType() {
        const sectors = JSON.parse(JSON.stringify(this.state.sectors));
        const sector = sectors.filter(sector => Number(sector.id) === Number(this.state.sectorId))[0];
        this.setState({
            sectorType: sector && sector.sector_type ? sector.sector_type : '',
        });
        this.resetQuantity();
    }

    resetQuantity() {
        this.setState({
            quantity: 0
        });
    }

    handleChangeQuantity(e) {
        const inputValue = e.target.value;
        /*
        if (inputValue < 1) {
            this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.selectASeatAtLeast);
            return;
        }
        */

        if (inputValue > this.state.seats.length) {
            this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.cantBuyMoreTickets
                + '.' + locales_es.errorModal.availableTicketsForThisSector + ':' + this.state.seats.length
            );
            return;
        }

        this.setState({
            quantity: inputValue
        }, () => {
            this.setQuantitySeatsIds();
        });
    }

    setQuantitySeatsIds() {
        const manualSeatsId = [];
        for (let i = 0; i < this.state.quantity; i++) {
            manualSeatsId.push(this.state.seats[i]);
        }
        this.setState({
            seatsIds: manualSeatsId
        });
    }

    setSectorsAvailability() {
        let sectorsChecked = 0;
        this.state.sectors.map(sector => {
            this.api.getSeats(this.state.performanceId, sector.id)
                .then((res) => {
                    sectorsChecked++;
                    if (res.data && !res.data.length) {
                        this.setState({
                            sectors: this.state.sectors.map(sec => {
                                if (sec.id === sector.id) {
                                    sec.disabled = true;
                                }
                                return sec;
                            })
                        })
                    }
                    if (sectorsChecked === this.state.sectors.length) {
                        this.setSectorsLoading(false);
                    }
                }).catch(err => {
                console.error(locales_es.errorModal.title, err.message);
            });
        });
    }

    setSectorImage() {
        const selectedSectorImage = this.state.sectors.filter(sector => {
            return Number(sector.id) === Number(this.state.sectorId)
        })[0].full_image;

        this.setState({
            selectedSectorImage,
        });
    }

    getSeats() {
        this.setState({
            seats: null,
            seatsIds: '',
        });
        this.api.getSeats(this.state.performanceId, this.state.sectorId)
            .then((res) => {
                this.setState({
                    seats: res.data.map(data => {
                        data.label = data.name;
                        data.value = data.id;
                        return data;
                    }),
                });
            }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, err.message)
        });
    }

    onChangeSeats(e) {
        this.setState({
            seatsIds: e
        });
    }

    validateForm() {
        // return true; // DEBUG
        if (!this.state.performanceId) {
            this.props.showMainModal(locales_es.errorModal.title, 'Elija la función a la que quiere asistir');
            return false;
        }

        if (!this.state.sectorId) {
            this.props.showMainModal(locales_es.errorModal.title, 'Elija el sector deseado');
            return false;
        }

        if (this.state.sectorType === SECTOR_TYPE_QTY && !this.state.quantity) {
            this.props.showMainModal(locales_es.errorModal.title, 'Elija la cantidad de tickets deseada');
            return false;
        }

        if (!this.state.seatsIds || !this.state.seatsIds.length) {
            this.props.showMainModal(locales_es.errorModal.title, 'Elija al menos un asiento');
            return false;
        }

        return true;
    }

    send() {
        if (this.validateForm()) {
            const objData = {
                performanceId: this.state.performanceId,
                sectorId: this.state.sectorId,
                seatsIds: this.state.seatsIds,
            };
            this.setState({
                objData
            });
            if (this.auth.isLoggedUser()) {
                this.setReviewStatus(true)
            } else {
                window.showModal();
            }
        }
    }

    setReviewStatus(bool) {
        this.setState({
            showReview: bool
        });
        this.props.history.push({
            search: `?review=${bool}`
        })
    }

    successMethod() {
        window.hideModal();
        this.setReviewStatus(true)
    }

    setSectorPrice() {
        const selectedSector = this.state.sectors.filter(sector => {
            return Number(sector.id) === Number(this.state.sectorId)
        })[0];

        this.setState({
            sectorPrice: selectedSector.price,
            serviceCharge: selectedSector.service_charge,
        });
    }

    getSeatsIds() {
        return this.state.seatsIds.map(seat => {
            return seat.id;
        });
    }

    confirm(totalPrice) {
        this.api.getConfig().then(res => {
            this.setState({
                checkout: res.data['payment-gateway'],
                stripePublicKey: res.data['stripe-public-key'],
                totalPrice,
            }, () => {
                if (this.state.checkout === MERCADO_PAGO_BASIC) {
                    this.goToMercadoBasic();
                }
            });
        }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.apiGetConfigError);
        });
    }

    renderStripeCheckout() {
        const data = {
            event_id: this.state.eventId,
            event_performance_id: this.state.performanceId,
            sector_id: this.state.sectorId,
            seats_ids: this.getSeatsIds(),
        };

        const success = () => {
            this.props.showMainModal(locales_es.checkoutSuccessModal.title, locales_es.checkoutSuccessModal.subtitle);
            this.props.history.replace(hrefCongratsSuccess)
        };

        const error = () => {
            this.props.showMainModal(locales_es.checkoutErrorModal.title, locales_es.checkoutErrorModal.subtitle);
        };

        window.stripeKey = this.state.stripePublicKey;

        return (
            <StripeCheckout stripePublicKey={this.state.stripePublicKey} totalPrice={this.state.totalPrice} data={data}
                            onSuccess={success} onError={error}/>
        )
    }

    goToMercadoBasic() {
        const data = {
            event_id: this.state.eventId,
            event_performance_id: this.state.performanceId,
            sector_id: this.state.sectorId,
            seats_ids: this.getSeatsIds(),
        };

        this.api.postCheckout(data).then(res => {
            window.location.href = res.data.init_point;
        }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        });
    }

    cancelCheckout() {
        this.setReviewStatus(false);
        this.setState({
            checkout: null,
        });
    }

    render() {

        const {event, sectorLoading, performances, sectors, seats, selectedSectorImage, showReview, checkout, sectorType} = this.state;

        return (
            <div>
                {this.state.loading ? <Loading/> : ''}
                <Header showMainModal={this.props.showMainModal}/>

                {checkout ?
                    <div>
                        <div className="container">
                            <a onClick={() => this.cancelCheckout()}
                               data-i18n="registerLink"> {locales_es.goBack}</a>
                        </div>
                        {this.state.checkout === STRIPE ? this.renderStripeCheckout() : <Spinner/>}
                    </div>
                    :
                    showReview && event ?
                        <div>
                            <div className="container">
                                <a onClick={() => this.setReviewStatus(false)}
                                   data-i18n="registerLink"> {locales_es.goBack}</a>
                            </div>
                            <Review image={event.full_image}
                                    title={event.title}
                                    theater={event.theater}
                                    performanceId={this.state.performanceId}
                                    objData={this.state.objData}
                                    sectorPrice={this.state.sectorPrice}
                                    serviceCharge={this.state.serviceCharge}
                                    confirm={this.confirm}
                            />
                        </div>
                        :
                        <div>

                            {event ?
                                <div>
                                    <div
                                        className="event-profile__subheader event-profile__subheader-desktop hidden-xs hidden-sm">
                                        <div className="container">
                                            <div className="row"><h1 className="ng-binding">{event.title}</h1></div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row event-profile">
                                            <div className="col-sm-12 col-md-6">
                                                <h4 className="reference-labels__title"><strong
                                                    data-i18n="bookingMap.mainTitle">Selección
                                                    de Asientos</strong></h4>
                                                <div className="row">
                                                    <h4>Elegir función</h4>
                                                    {performances ?
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) => this.onChangePerformance(e)}
                                                            value={this.state.performanceId}
                                                            required="required">
                                                            {performances.map(perf => {
                                                                return (
                                                                    <option value={perf.id} key={'perf' + perf.id}>
                                                                        {this.dateTimeService.parseEventDate(perf.date, false, 'full-string')} {this.dateTimeService.parseEventTime(perf.date, 'full-string')}
                                                                    </option>
                                                                )
                                                            })
                                                            }
                                                        </select>
                                                        :
                                                        <Spinner/>
                                                    }
                                                    <br/>
                                                </div>


                                                <div className="row">
                                                    <h4 data-i18n="eventTicketsSelection.areasLabel">Elegir sector</h4>
                                                    {sectors && !sectorLoading ?
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) => this.onChangeSector(e)}
                                                            value={this.state.sectorId}
                                                            required="required">
                                                            {sectors.map(sector => {
                                                                return (
                                                                    <option value={sector.id}
                                                                            key={'sector' + sector.id}
                                                                            disabled={sector.disabled}>
                                                                        {sector.disabled ? '(AGOTADO)' : ''} {sector.name} -
                                                                        ${sector.price}
                                                                    </option>
                                                                )
                                                            })
                                                            }
                                                        </select>
                                                        :
                                                        <Spinner/>
                                                    }
                                                    <br/>
                                                </div>


                                                <div className="row">
                                                    <h4 data-i18n="eventTicketsSelection.areasLabel">
                                                        {
                                                            sectorType === null ?
                                                                '' :
                                                                sectorType === SECTOR_TYPE_QTY
                                                                    ? locales_es.selectTicketsQuantity
                                                                    : locales_es.chooseSeats
                                                        }
                                                    </h4>
                                                    {seats ?
                                                        seats.length ?

                                                            sectorType === SECTOR_TYPE_QTY ?
                                                                <input className="form-control"
                                                                       type="number"
                                                                       min={1}
                                                                       max={seats.length}
                                                                       value={this.state.quantity}
                                                                       onChange={(e) => this.handleChangeQuantity(e)}/>
                                                                :
                                                                <MultiSelect
                                                                    options={seats}
                                                                    value={this.state.seatsIds}
                                                                    onChange={(e) => this.onChangeSeats(e)}
                                                                    hasSelectAll={false}
                                                                    labelledBy={"Select"}
                                                                    overrideStrings={
                                                                        {
                                                                            "selectSomeItems": "Selecione sus asientos...",
                                                                            "allItemsAreSelected": "Todos los asientos disponibles.",
                                                                            "selectAll": "Seleccionar todos",
                                                                            "search": "Buscar",
                                                                            "clearSearch": "Limpiar"
                                                                        }
                                                                    }
                                                                />
                                                            :
                                                            <h3 data-i18n="eventTicketsSelection.areasLabel">No hay más
                                                                asientos
                                                                disponibles en este sector</h3>
                                                        :
                                                        <Spinner/>
                                                    }
                                                    <br/>
                                                </div>

                                                <div className="row">
                                                    <button onClick={() => this.send()} type="button"
                                                            id="layout-btn-done"
                                                            className="btn btn-primary">
                                                        Continuar
                                                    </button>
                                                </div>

                                            </div>


                                            <div className="col-sm-12 col-md-6">
                                                <h4 className="reference-labels__title">
                                                    <strong data-i18n="bookingMap.title">Referencias y Precios</strong>
                                                </h4>
                                                <h4 className="reference-labels__title">
                                                    <strong data-i18n="bookingMap.firstFloor">SECTOR ELEGIDO</strong>
                                                </h4>
                                                <div className="row">
                                                    <div className="col">
                                                        <InnerImageZoom src={selectedSectorImage}
                                                                        zoomSrc={selectedSectorImage}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="container" ng-show="browser.desktop">
                                        <div className="row">
                                            <div className="col-12">
                                                <h4 className="reference-labels__title"><strong
                                                    data-i18n="bookingMap.secondFloor">MAPA DEL TEATRO</strong></h4>
                                                <div className="row">
                                                    <div className="col">
                                                        {event.full_map_image ?
                                                            <InnerImageZoom src={event.full_map_image}
                                                                            zoomSrc={event.full_map_image}/>
                                                            : <Loading/>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                :
                                <Loading/>
                            }


                            <div id="myModal" className="modal fade" tabIndex="-1" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <RegisterPage modalMode={true}
                                                          successMethod={() => this.successMethod()}
                                                          showMainModal={this.props.showMainModal}
                                                          eventId={this.state.eventId}
                                                          pathname={this.props.location.pathname}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }

            </div>
        )
    }
}

export default withFooter(CheckoutMultiSelectPage);
