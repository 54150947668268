import React, {Component} from 'react';
import Header from "../../components/header";
import {withFooter} from "../../components/footerHOC";
import Form from "./../../components/form";
import locales_es from "../../locales/es";
import {hrefDashboard} from "../../models/constants";
import AuthService from "../../modules/authService";
import Loading from "./../../components/loading";


class LoginPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			email: '',
			password: '',
		};

		this.auth = new AuthService();
	}

	componentWillMount() {
		this.checkUserStatus();
	}

	async checkUserStatus() {
		const isLoggedIn = await this.auth.isLoggedUser();
		if (isLoggedIn) {
			this.successLoginRedirect();
		}
	}

	handleChange = state => ev => {
		this.setState({[state]: ev.target.value});
	};

	validateForm() {
		return !this.state.email || !this.state.password
		  ? this.props.showMainModal('Error', 'Complete todos los campos del formulario')
		  : true;
	}

	login() {
		if (this.validateForm()) {
			this.setLoading(true);
			// want to show the loading a little more the first time
			setTimeout(() => {
				this.auth.login(this.state.email, this.state.password)
				  .then(() => {
					  this.successLoginRedirect();
					  this.setLoading(false);
				  }).catch(err => {
					console.log(err);
					this.props.showMainModal(locales_es.errorModal.title, err.message);
					this.setLoading(false);
				});
			}, 3000);
		}
	}

	successLoginRedirect() {
		if(this.props.modalMode) {
			this.props.successMethod();
		} else {
			window.location.href = hrefDashboard;
		}
	}

	setLoading(bool) {
		this.setState({
			loading: bool
		});
	}

	render() {

		const inputs = [
			{
				label: 'Email',
				placeholder: 'Email',
				id: '1',
				state: 'email',
				value: this.state.email,
				type: 'email',
				required: true
			},
			{
				label: 'Contraseña',
				placeholder: 'Contraseña',
				id: '2',
				state: 'password',
				value: this.state.password,
				type: 'password',
				required: true
			},
		];

		return (
		  <div>

			  {this.state.loading ? <Loading/> : ''}

			  {this.props.modalMode ? null :
			    <Header showMainModal={this.props.showMainModal}/>
			  }

			  <div>

				  <Form
				    inputs={inputs}
				    handleChange={this.handleChange}
				    onSubmit={() => this.login()}
				    onSubmitButtonText={locales_es.send}
				  >
					  <h2 className="form-signin-heading" data-i18n="loginForm.title">Iniciar Sesión</h2>
					  <p data-i18n="loginForm.p">Introduce tu usuario y tu contraseña</p>
				  </Form>

				  <p className="tcenter">
					  <a id="pwdReqBtn"
					     href="/recovery"
					     data-i18n="forgotPasswordLink">Olvidé mi contraseña</a>
				  </p>

				  {this.props.modalMode ?
				    null
				  :
				  <div className="container-fluid tcenter first-time-register-link">
					  <span data-i18n="loginForm.registerText1">¿No tienes cuenta?</span>
					  &nbsp;
					  <a href="/register"
					     data-i18n="registerLink">Regístrate</a>.&nbsp;
					  <span data-i18n="loginForm.registerText2">Es muy fácil!</span>
				  </div>
				  }

			  </div>


		  </div>
		)
	}
}

export default LoginPage;