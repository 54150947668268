import React, {Component} from 'react';
import {CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe} from 'react-stripe-elements';
import APIService from "../../modules/apiService";

import Rodal from 'rodal';
import locales_es from "../../locales/es";

class StripeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            modalTitle: '',
            modalContent: '',
            sendingFlag: false
        };

        this.submit = this.submit.bind(this);
        this.api = new APIService();
    }

    processTokenError(error){
        const stripeErrMsg = error.message;
        // Show the errors on the form:
        this.showModal("Error", stripeErrMsg);
        this.setState({
            sendingFlag: false
        }); // Re-enable submission

        /*// LOGS
        var data = {
            tag: 'karolsevilla-stripe-error-' + error.code,
            data: error
        };

        this.api.logError(data).then((res) => {
            console.log(res);
        }).catch((err) => {
            throw new Error(err);
        });*/
    }

    processToken(token){
        const data = Object.assign({}, this.props.data, {
            token: token.id,
        });
        // API SEND
        this.api.postCheckoutStripe(data)
            .then((res) => {
                this.setState({
                    sendingFlag: 'sended'
                }); // Disable new submition
                this.props.onSuccess();
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    sendingFlag: false
                }); // Re-enable submission
                let errMsg = err && err.message ? err.message : "Hubo un error al cargar los datos.";
                errMsg += '. ' + locales_es.hot_error_msg;
                this.showModal("Error", errMsg);
            });
    }

    async submit(ev) {
        ev.preventDefault();

        this.setState({
            sendingFlag: true
        });

        let {token, error} = await this.props.stripe.createToken();

        if(error) {
            console.log(error);
            this.processTokenError(error);
        }
        if(token) {
            console.log(token);
            this.processToken(token);
        }
    }

    /* MODAL Functions */
    // TODO: Centralizar en componente Modal
    showModal(title, content) {
        this.setState({ modalTitle: title });
        this.setState({ modalContent: content });
        this.show();
    }
    show() {
        this.setState({ visible: true });
    }

    hide() {
        this.setState({ visible: false });
    }
    //

    render() {
        const styles = {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '20px',
                '::placeholder': {
                    color: '#aab7c4'
                },
                textAlign: 'center',
                lineHeight: '42px'
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };

        let buttonClassName = 'btn btn-primary';
        const buttonText = this.state.sendingFlag ? 'Enviando' : 'Pagar';

        return (
            <div className="checkout">
                {/*<CardElement hidePostalCode={true} />*/}
                <div className="eventticket-card-form---input text-center">
                    <CardNumberElement style={styles} placeholder="Tarjeta de Crédito"/>
                </div>
                <div className="eventticket-card-form---label text-center">
                    <label>Expiración</label>
                </div>

                <div className="eventticket-card-form---input text-center">
                    <CardExpiryElement style={styles} />
                </div>
                <div className="eventticket-card-form---input text-center">
                    <CardCVCElement  style={styles} placeholder="Código de seguridad" />
                </div>
                <div className="eventticket-card-form--info-label text-center">
                    TOTAL: <span>{locales_es.currency_sign} {this.props.totalPrice}</span>
                </div>
                <div className="tcenter mt-5">
                    {this.state.sendingFlag !== 'sended' ?
                        <button className={buttonClassName}
                                onClick={this.submit}
                                type="submit"
                                disabled={this.state.sendingFlag}>
                            {buttonText}
                        </button>
                    :
                        <span className="btn"
                              disabled="disabled">Enviado</span>
                    }
                </div>

                <Rodal width={window.screen && window.screen.availWidth ? window.screen.availWidth * 0.85 : '300'}
                       visible={this.state.visible} onClose={() => this.hide()}>
                    <h4 className="rodal-title">{this.state.modalTitle}</h4>
                    <div className="rodal-body">{this.state.modalContent}</div>
                    <div className="rodal-footer">
                        <button className="btn btn-primary" type="button"
                                onClick={() => this.hide()}>Aceptar</button>
                    </div>
                </Rodal>
            </div>
        );
    }
}

export default injectStripe(StripeForm);
