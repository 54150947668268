export const userDataLocalStorage = 'user_data';

// TIMEZONE
export const defaultTimeZone = 'America/Argentina/Buenos_Aires';

// STORAGE & USER CONSTANTS
export const userAccessToken = 'token';

export const SEAT_SELECTOR_TYPE = 'seat_selection_type';
export const SEAT_SELECTOR_TYPE_MAP = 'map';
export const SEAT_SELECTOR_TYPE_HYBRID = 'hybrid';
export const SEAT_SELECTOR_TYPE_MULTISELECT = 'multiselect';
export const seatSelectorId = 'svgMap';
export const mapZoomInBtnClassName = 'eventticket--mobile-zoom-in';
export const mapZoomOutBtnClassName = 'eventticket--mobile-zoom-out';

export const mapFullScreenBtnClassName = 'eventticket--fullscreen-btn';

export const SEAT_SELECTION_CLASSNAME = 'selected';
export const SECTOR_TYPE_QTY = 'quantity';

export const SEAT_SECTOR_ID_ATTR = 'sectorid';
export const SEAT_PRICE_ATTR = 'seatprice';
export const SEAT_SERVICE_CHARGE = 'seatservicecharge';
export const SEAT_FINAL_PRICE_ATTR = 'seatfinalprice';
export const SEAT_ID_ATTR = 'seatid';
export const SEAT_NAME_ATTR = 'seatname';
export const SEAT_DISABLED_ATTR = 'seatdisabled';

export const SEAT_FILTERED_ATTR = 'frontendfiltered';

// LOGGED DEFAULT PAGE

// HREF
export const hrefLogin = '/login';
export const hrefRegister = '/register';
export const hrefRecovery = '/recovery';
export const hrefEditMyProfile = '/edit-my-profile';
export const hrefChangePassword = '/change-password';
export const hrefHome = '/home';
export const hrefDashboard = '/dashboard';
export const hrefMyProfile = '/my-profile';
export const hrefCongratsSuccess = '/congrats/success';

// API ERROR CODES
export const emailExists = 'email_exists';

// Payment Gateway ID's
export const MERCADO_PAGO_BASIC = 'mercadopago_basic';
export const STRIPE = 'stripe';

// USER TYPES
export const USER_TYPE_PATIENT = 'patient';
export const USER_TYPE_DOCTOR = 'medic';


// SCRIPTS IDS
export const stripeScriptId = 'stripe-library-script';

// HEADER
export const headerNavId = 'headerNav';
export const headerNavLogoutLinkId = 'headerNavLogoutLink';
export const headerNavWrapperId = 'headerNavWrapper';
export const setNavLinksEvent = 'setNavLinks';
export const setHiddenHeaderEvent = 'setHiddenHeader';

export const REACT_APP_STRIPE_LIBRARY='https://js.stripe.com/v3/'
