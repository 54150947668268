import React, {Component} from 'react';
import "./index.css";
import mantenainceIcon from "./../../images/mantenaince.png";
import locales_es from "../../locales/es";

export default class MantenainceMode extends Component {

	render() {
		return (
			<div className="container mantenaince-container">
				<div className="row">
					<div className="col text-center">
						<img src={mantenainceIcon} />
						<br />
						<br />
						<br />
					</div>
				</div>
				<div className="row">
					<div className="col text-center">
						<div className="alert alert-warning" role="alert">
							<div className="alert-text">
								{locales_es.mantenainceMode}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
