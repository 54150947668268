import React, {Component} from 'react';


export default class Input extends Component {

	togglePasswordVisibility(ev) {
		const target = ev.target.previousSibling;
		const actualType = target.type;
		target.type = actualType === 'password' ? 'text' : 'password';

		const input = ev.target;
		input.classList.toggle('icon-eye__closed');
	}

	getInputByType(params) {
		const {id, value, placeholder, required, disabled, handleChange, customClassName} = this.props;
		const defaultClassName = 'form-control ';

		switch (params) {
			case 'email':
				return (
				  <input className={defaultClassName + customClassName} type="email"
				         onChange={handleChange}
				         value={value}
				         placeholder={placeholder}
				         key={id}
				         required={required}
				         disabled={disabled}
				  />
				);
			case 'text':
				return (
				  <input className={defaultClassName + customClassName} type="text"
				         onChange={handleChange}
				         value={value}
				         placeholder={placeholder}
				         key={id}
				         required={required}
				         disabled={disabled}
				  />
				);
			case 'password':
				return (
				  <div>
					  <input className={defaultClassName + customClassName} type="password"
					         onChange={handleChange}
					         value={value}
					         placeholder={placeholder}
					         key={id}
					         required={required}
					         disabled={disabled}
					         id={id}
					  />
					  <i onClick={(ev) => this.togglePasswordVisibility(ev)} className="icon-eye icon-eye__closed"></i>
				  </div>
				);
			default:
				break;
		}
	};

	render() {
		return (
		  <div>
			  <label className="sr-only" htmlFor="inputName">{this.props.label}</label>
			  {this.getInputByType(this.props.type)}
		  </div>
		);
	}
}