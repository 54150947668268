export const analyticsConfig = [
    {
        id: 1,
        event_id: 5,
        facebook: {
            pixel: '388297815946398',
            pageViews: ['/event/5']
        },
        google: {
            id: 'AW-1008712534',
            events: {
                loadEvent: 'AW-1008712534/oWotCObzjcgCENb2_uAD',
                loadCheckout: 'AW-1008712534/qjIYCKXqtcgCENb2_uAD',
                registerCheckout: 'AW-1008712534/1CuHCKCBtsgCENb2_uAD',
                confirm: 'AW-1008712534/9tnECNextsgCENb2_uAD',
            }
        }
    },
    {
        id: 2,
        event_id: 6,
        facebook: {
            pixel: '563287171720774',
            pageViews: ['/event/6']
        },
    },
];
